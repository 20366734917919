<template>
    <div>
        <CCard class="p-2">
           
            <form @submit="saveIncident" enctype="multipart/form-data"> 
                <CCardHeader>
					<CRow style="color: #1352a1;">
				
						<CCol lg="6">
                            <h5 v-if="is_view_only">View Incident/#{{dataParams.incident_id}} </h5>
							<h5 v-else-if="!editMode">New Incident </h5>
                            <h5 v-else>Update Incident/#{{dataParams.incident_id}}</h5>
						</CCol>
				     	<CCol lg="6">
				      		<div style="text-align: right" v-if="!is_view_only">
				      			<CButton size="sm" class="btn btn-ghost-dark mr-1" @click="backToTable()" >Cancel</CButton> 
					      		<CButton class="px-3 mr-1"  size="sm" color="info" type="submit">Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="card-form">
                    <CCard class="pl-3 pr-3">
                        <CRow class="mt-3 mb-2">
                            <CCol lg="12">
                                <h6>Incident Details</h6>
                            </CCol>   
                        </CRow>
                        <CRow>
                        <CCol class="col-lg-12" >
                                <div class="alert alert-info" role="alert">
                                        <h6><i class="fa fa-warning"></i> Instruction</h6>
                                        <small class="mb-0">
                                            <strong>Use this form when reporting any of the following. Check the box referring to the type of incident being report.</strong>          
                                        </small>
                                        <hr class="my-1">
                                        <small class="mb-0">
                                        <em>This Incident Report must be completed and endorsed to Operation Manager with all the 
                                            necessary documents not later than 36 hours after occurrence or discovery of incident. 
                                        </em>
                                        </small>
                                    </div>
                         </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <label>Incident Type <span class="text-danger">*</span></label>
                            </CCol>

                            <CCol lg="12" v-for="(type, index) of incident_type_arr" :key="index">
                                <div class="form-group"> 
                                    <CInputCheckbox :checked="isChecked(type.id)" @change="$event => setIncidentType($event, type)" :label="type.label" :disabled="isChildrenSelected(type.id) || is_view_only" /> 
                                     <template v-if="type.children">
                                        <template v-if="type.children_type == 'checkbox'">
                                            <div v-show="isChecked(6)" class="col-12 pl-4 mt-2" v-for="(child, x) of type.children" :key="x">
                                                <div class="form-group mb-2"> 
                                                    <CInputCheckbox :disabled="is_view_only" :checked="isChecked(child.id)" @change="$event => setIncidentType($event, child)" :label="child.label" /> 
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else-if="type.children_type == 'input'">
                                            <textarea :disabled="is_view_only" v-for="(child, x) of type.children" :value="getOtherValue(child)" @keyup="$event => removeOtherValue($event, child)" @change="$event => setOtherValue($event, child)" :key="x" class="form-control mx-4 mt-2 col-md-11" v-show="isChecked(8)">
                                                
                                            </textarea>
                                        </template>
                                    </template>
                                </div> 
                            </CCol>
                           
                        </CRow>
                        <CRow class="mt-2 mb-1">
                            <CCol lg="12">
                                <h6>Reporter Information</h6>
                            </CCol>
                            
                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Reported By <span class="text-danger">*</span></label>
                                    <CInput   
                                        placeholder=""
                                        v-model="dataParams.report_by" 
                                        :disabled="is_view_only"
                                        required
                                        />
                                                
                                </div> 
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label>Date Reported<span class="text-danger"> *</span></label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.date_reported"
                                        :disabled="is_view_only"
                                        :required="true"
                                        :typeable="true"
										
									>
									</Datepicker> 
								</div> 
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label>Position<span class="text-danger"> *</span></label>
                                    <CInput   
                                        placeholder=""
                                        v-model="dataParams.position" 
                                        :disabled="is_view_only"
                                        required
                                        />
                                                
                                </div> 
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label >Division/Section<span class="text-danger"> *</span></label>
                                    <CInput   
                                        placeholder=""
                                        v-model="dataParams.division" 
                                        :disabled="is_view_only"
                                        required
                                        />
                                                
                                </div> 
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label >IR NO.<span class="text-danger"> *</span></label>
                                    <CInput   
                                        placeholder=""
                                        v-model="dataParams.ir_no" 
                                        :disabled="is_view_only"
                                        required
                                        />
                                                
                                </div> 
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label>Status<span class="text-danger"> *</span></label>
                                    <v-select label="text" :options="['Open', 'Closed']" v-model="dataParams.status"
                                        :disabled="is_view_only">
                                        <template #search="{ attributes, events }">
                                            <input class="vs__search" :required="!dataParams.status" v-bind="attributes"
                                                v-on="events" />
                                        </template>
                                    </v-select>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow class="mt-2 mb-1">
                            <CCol lg="12">
                                <h6>Description of the Incident</h6>
                            </CCol>
                            
                        </CRow>   
                        <CRow>
                            <CCol lg="12">
                            <div class="form-group"> 
                                            <label class="mb-1"> Location of Incident <span class="text-danger"> *</span></label>
                                            <v-select 
                                                label="setting_name" 
                                                :options="originList.data"
                                                :reduce="item => item.id"
                                                v-model="dataParams.current_location_id"
                                                :disabled="is_view_only"
                                                placeholder="-Select-" 
                                                
                                            >
                                                <template #search="{attributes, events}">
                                                    <input
                                                        class="vs__search"
                                                        :required="!dataParams.current_location_id"
                                                        v-bind="attributes"
                                                        v-on="events"
                                                    />
                                                </template>
                                            </v-select>
                            </div>
                            </CCol>
                        </CRow> 
                        <CRow>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label>Incident Date & Time <span class="text-danger">*</span></label>
                                    <Datetime format="MM-DD-YYYY H:i" :enabled="false" v-model="dataParams.incident_date" 
                                     :required="true"></Datetime>

                                </div> 
                            </CCol>
                            <CCol lg="6">
                                <label>Cost (How much are involved) </label>
                                <CInput
                                v-model="dataParams.cost"  
                                :disabled="is_view_only" 
                                />
                            </CCol> 
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CTextarea
                                label="Sequence of Events"
                                v-model="dataParams.sequence_of_events"   
                                :disabled="is_view_only"
                                placeholder=""
                                rows="5"
                                />
                            </CCol> 
                        </CRow> 
                        <CRow>
                            <CCol lg="12">
                                <CTextarea
                                label="Person(s) directly involved Witness(es)"
                                v-model="dataParams.persons_involved"   
                                :disabled="is_view_only"
                                placeholder=""
                                rows="5"
                                />
                            </CCol> 
                        </CRow> 

                        <CRow>
                            <CCol lg="12">
                                <div class="form-group">
                                    <label for="exampleFormControlFile1">Describe attached supporting documents (If necessary)</label>
                                    <br>
                                    <label v-if="is_view_only">
                                        <template v-if="dataParams.documents_s3 == 0 && !dataParams.documents">
                                            <i class="text-danger">No attached file</i>
                                        </template>
                                        <template v-else>
                                            <template v-if="dataParams.documents_s3 == 0">
                                                <a target="_blank" :href="main_path+'/incident_documents/'+ dataParams.documents">{{ dataParams.documents }}</a>
                                                
                                            </template>
                                            <template v-else>
                                                <a target="_blank" :href="dataParams.documents_s3">{{ dataParams.documents_s3 }}</a>
                                            </template>
                                            
                                        </template>
                                        
                                    </label>
                                    <input v-if="!is_view_only" class="form-control-file" type="file"  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            accept="image/*,.pdf,.doc,.docx">
                                </div>
                            </CCol>
                            
                        </CRow>
                        <CRow>
                                <CCol lg="12">
                                    <CTextarea
                                    label="Gaps"
                                    v-model="dataParams.gaps"   
                                    placeholder=""
                                    :disabled="is_view_only"
                                    rows="5"
                                    />
                                </CCol>  
                        </CRow> 
                        <CRow>
                                <CCol lg="12">
                                    <CTextarea
                                    label="Actions are taken and/or Recommendations"
                                    v-model="dataParams.actions_recommendations"   
                                    placeholder=""
                                    :disabled="is_view_only"
                                    />
                                </CCol>  
                        </CRow> 
                        <br><br>
                        <CRow>
                                <CCol lg="12">
                                    <label>____________________________________________________</label><br>
                                    <label>Signature over printed name of reporting person</label>
                                </CCol>  
                        </CRow> 
                        <br><br>
                        <CRow class="mt-2 mb-1">
                            <CCol lg="12">
                                <h6>Immediate Superior’s Findings / Comments / Recommendations</h6>
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label>Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.superior_date_recommendation"
                                        :disabled="is_view_only"
                                        rows="5"
										
									>
									</Datepicker> 
								</div> 
                            </CCol>
                            <CCol lg="12">
                                    <CTextarea
                                    label="Findings/Comments/Recommendation"
                                    v-model="dataParams.superior_comments"   
                                    placeholder=""
                                    :disabled="is_view_only"
                                    rows="5"
                                    />
                                </CCol>  
                            
                        </CRow>
                        <br>
                        <br>
                        <CRow>
                                <CCol lg="12">
                                    <label>____________________________________________________</label><br>
                                    <label>Signature over printed name of Immediate Superior</label>
                                </CCol>  
                        </CRow> 
                     </CCard>
                </CCardBody>
         
 
            
           </form>
        </CCard>
    </div>
</template>
<script>

import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import Datetime from 'vuejs-datetimepicker';
import vSelect from 'vue-select'
import '../style.css';
import moment from 'moment';
    
    export default {
        mounted(){   
            
            this.getOrigin();

            if(this.$route.params?.id) {
                this.getData()
            }
        },
        computed: {
            is_view_only() {
                if(this.$route.params?.view) {
                    return true;
                }
                return false;
            },
            getOtherValue() {
                return index => {
                    const res = this.incident_type.filter(i => i.id == index.id);
                    if(res.length > 0) {
                        return res[0].value;
                    }

                    return '';
                }
            },
            isChecked() {
                return index => {
                    const res = this.incident_type_holder.filter(i => i.id == index);
                    return res.length > 0;
                }
            },
            isChildrenSelected() {
                return index => {
                    const res = this.incident_type.filter(i => i.parent == index);
                    return res.length > 0;
                }
            },
            editMode() {
                return this.$route.params?.id ? true : false
            }
        },
        data() {
            return {
                main_path: config.main_path,
                incident_type: [],
                incident_type_holder: [],
                dataParams: {
                    id:null,
                    report_by: null,
                    date_reported: null,
                    position: null,
                    division:null,
                    ir_no:null,
                    current_location_id:null,
                    incident_date:null,
                    cost:null,
                    sequence_of_events:null,
                    persons_involved:null,
                    documents:null,
                    gaps:null,
                    actions_recommendations:null,
                    superior_date_recommendation:null,
                    superior_comments:null,
                    file:null,
                    status: 'Open',
                    incident_id:null,
                    documents_s3: 0
                },
                originList: [],
                incident_type_arr: [
                    {
                        id: 1,
                        label: 'Pilferage / Theft',
                    },
                    {
                        id: 2,
                        label: 'Client Complaint',
                        
                    },
                    {
                        id: 3,
                        label: 'Deviation from code of Discipline, Company Policies and Prescribe System by the Company Employee and /or Security Guard ',
                        
                    },
                    {
                        id: 4,
                        label: 'Damage to Property and Equipment',
                        
                    },
                    {
                        id: 5,
                        label: 'Argument between employees and Security Guard',
                        
                    },
                    {
                        id: 6,
                        label: 'Violence within and outside company premises',
                        children_type: 'checkbox',
                        children: [
                            {
                                id: 6.1,
                                label: 'Between an outsider and company employee',
                                parent: 6
                            },
                            {
                                id: 6.2,
                                label: 'Between an outsider and another outsider',
                                parent: 6
                            },
                        ]
                        
                    },
                    {
                        id: 7,
                        label: 'Deviation from company rules and guidelines by Supplier, Merchandiser and/or re-packers, etc.',
                    },
                    {
                        id: 8,
                        label: 'Others',
                        children_type: 'input',
                        children: [
                            {
                                id: 8.1,
                                value: '',
                                parent: 8
                            },
                        ]
                    },
                    
                    
                ],
            }
        },
        components: { Datepicker,vSelect,Datetime},
        methods :{
            getData(){
                this.$showLoading(true)
                axios.get(config.api_path+"/incident/"+this.$route.params.id)
                    .then(response=>{
                        this.$showLoading(false)
                        const {data} = response.data;

                        for (const key in data) {
                            if (Object.hasOwnProperty.call(this.dataParams, key)) {
                                const element = data[key];
                                this.dataParams[key] = element;
                                
                            }
                        }
                        // this.dataParams = data;
                        if(data?.incident_type) {
                            this.incident_type = [...JSON.parse(data.incident_type.detail)];
                            this.incident_type_holder = [...JSON.parse(data.incident_type.raw_detail)];
                            // this.incident_type = data.incident_type[0]
                        }
                    })
                    .catch(err => {
                    this.$showLoading(false)
                })
            },
            filesChange(name, file) {
                const files = file.length ? file[0] : null;
                this.dataParams.file = null;
                if(files && files.size) {
                    if(config.fileSize(files.size) <= 5) {
                        this.dataParams.file = files;
                    }
                    else {
                        Swal.fire({
                            title: 'Error!',
                            text: 'File size must not be greater than 5MB',
                            icon: 'error',
                        })
                        const f = document.querySelector('.form-control-file');
                        f.value = '';
                    }
                }
            },
            removeOtherValue(ev, type) {
                const val = ev.target.value;
                let indx = -1, indx2 = -1;
                if(val.length == 0) {
                    for (let index = 0; index < this.incident_type.length; index++) {
                        if(this.incident_type[index].id == type.id) {
                            indx = index;
                            break;
                        }
                        
                    }
                    for (let index = 0; index < this.incident_type_holder.length; index++) {
                        if(this.incident_type_holder[index].id == type.id) {
                            indx2 = index;
                            break;
                        }
                        
                    }
                    
                    if(indx >= 0)
                        this.incident_type.splice(indx,1);
                    if(indx2 >= 0)
                        this.incident_type_holder.splice(indx2, 1);

                }
            },
            setOtherValue(ev, type) {
                const val = ev.target.value;
                let type_holder = {...type};
                if(val.length == 0) return;

                let indx = -1;
                let indx2 = -1;
                for (let index = 0; index < this.incident_type.length; index++) {
                    if(this.incident_type[index].id == type.id) {
                        indx = index;
                        break;
                    }
                    
                }
                for (let index = 0; index < this.incident_type_holder.length; index++) {
                    if(this.incident_type_holder[index].id == type.id) {
                        indx2 = index;
                        break;
                    }
                }
                type_holder.value = val;

                if(indx >= 0) {
                    this.incident_type[indx] = type_holder;
                    this.incident_type_holder[indx2] = type_holder;
                }
                else {
                    this.incident_type.push(type_holder);
                    this.incident_type_holder.push(type_holder);
                }

            },
            setIncidentType(ev, type) {
                const checked = ev.target.checked;
                console.log(checked, type, [...this.incident_type], [...this.incident_type].indexOf(type))
                let arr = [...this.incident_type];
                let arr2 = [...this.incident_type_holder]; 
                if(checked) {
                    this.incident_type_holder.push(type);

                    if(!type.children) //if naa anak2 , dili ipush sa array
                        this.incident_type.push(type)
                }
                else {
                    if(!type.children) {
                        const index = arr.indexOf(type);
                        this.incident_type.splice(index,1);

                        console.log(index, 'index')
                    }
                    const index2 = arr2.indexOf(type);
                    console.log(index2)
                    this.incident_type_holder.splice(index2, 1)
                }
                
                console.log(this.incident_type)
                 console.log(this.incident_type_holder)

                
            },
            backToTable(){
	    	this.$router.push('/main/incident')
	     },
            saveIncident(e) {
                e.preventDefault();
                let vm = this;

   
                const header = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                if(vm.dataParams.incident_date == ""){
                    vm.dataParams.incident_date = null;
                }
                else{
                    vm.dataParams.incident_date = moment(vm.dataParams.incident_date, "MM/DD/YYYY H:mm").valueOf() / 1000; 

                }  
    
                let formData = new FormData();
                vm.dataParams.date_reported = moment(vm.dataParams.date_reported).format('MM/DD/YYYY');
                vm.dataParams.superior_date_recommendation = vm.dataParams.superior_date_recommendation ? moment(vm.dataParams.superior_date_recommendation).format('MM/DD/YYYY') : null;

                for (const property in vm.dataParams) {
                    formData.append(property, vm.dataParams[property]);
                }

                
                
                formData.append('incident_type', JSON.stringify(vm.incident_type));
                formData.append('raw_incident_type', JSON.stringify(vm.incident_type_holder));
                vm.isLoading = false;
   
                vm.$showLoading(true)
                axios.post(`${config.api_path}/v2/incident`, formData, header)
                .then(res => {
                    vm.$showLoading(false);
                    this.backToTable()
                }).catch(error => {  
                    vm.$showLoading(false)
                });
            },
            getOrigin(){
                axios.get(config.api_path+'/reference/settings-list',{
                    params:{
                    setting_type:'origin'
                    }
                        })
                    .then(response => {
                        this.originList = response.data; 
                    })

                },
        },
        
    }
</script>
    